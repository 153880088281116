import React, {useState, useEffect} from "react";
import { graphql, Link } from "gatsby";
import Layout from "../components/Layout";
import SEO from "../components/SEO";
import styled from "styled-components";
import Img from "gatsby-image";
import { BLOCKS, INLINES } from '@contentful/rich-text-types';
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import AnchorLink from 'react-anchor-link-smooth-scroll';

import Breadscrum from "../components/Breadscrum";
import ArticleCta from "../components/ArticleCta";
import PopUp from "../components/PopUp";
import PopUpZaveti from "../components/PopUpZaveti";
import CenovaKalkulacka from "../components/CenovaKalkulacka";
import ArticleWidget from "../components/ArticleWidget";
import ArticleFormular from "../components/ArticleFormular";
import ArticleWidgetZaveti from "../components/ArticleWidgetZaveti";
import SeVsimPomuzeme from "../components/pohrby/SeVsimPomuzeme";


export const query = graphql`
  query($slug: String!) {
    allContentfulAsset {
      nodes {
        file {
          url
          contentType
        }
        fluid(maxWidth: 930, maxHeight: 600) {
          ...GatsbyContentfulFluid_noBase64
        }
      }
    }

    allContentfulSlovnicek(filter: {slug: {eq: $slug }}) {
      edges {
        node {
          id
          slug
          seoTitle
          perex2 {
            perex2
          }
          seoDesc {
            seoDesc
          }
          imageSeoAlt
          h1
          content {
            json
          }
          image {
            fixed(width: 350) {
              ...GatsbyContentfulFixed
            }
          }
        }
      }
    }
  }
`;


const SlovnikTemplateInner = styled.section`  
  .wrapper {
    max-width: 1150px;
    width: calc(100% - 50px);
    margin: 0 auto;
    padding: 40px 0;

    h1 {
      margin-top: 20px;
      font-size: 36px;
      font-family: NewKansas;
      color: #243A58;
    }

    .perex {
      font-size: 18px;
      line-height: 27px;
      margin-top: 25px;
      color: #394B5C;
    }

    .hlavniFoto {
      margin-top: 30px;
      margin-bottom: 10px;
      max-width: 100%;
      border-radius: 5px;
    }

    .content {
      p {
        font-family: Visuelt-Regular;
        font-size: 16px;
        line-height: 25px;
        color: #394B5C;
        margin-bottom: 20px;
      }

      h2 {
        margin-bottom: 20px;
        margin-top: 40px;
        font-family: Visuelt-Medium;
        font-size: 25px;
        color: #243A58;
      }

      h3 {
        margin-bottom: 15px;
        margin-top: 25px;
        font-family: Visuelt-Medium;
        font-size: 20px;
        color: #243A58;
      }

      ul {
        padding-left: 25px;
      }

      ol {
        padding-left: 25px;
      }

      a {
        color: #243A58;
      }

      b {
        margin: 0 5px;
      }

      .image {
        max-width: 350px;
        width: 100%;
        margin-bottom: 30px;
        margin-top: 30px;
        border-radius: 10px;
      }
    }
  }
`;

const SlovnikTemplate = (props) => {
  const data = props.data.allContentfulSlovnicek.edges[0].node;

console.log(data);
  const structuredData = {
    "@context": "https://schema.org",
    "@type": "Article",
    "headline": data.h1,
    "image": [
      data.image.fixed.src,
      ],
    "author": {
      "@type": "Person",
      "name": "Goodbye.cz",
    },
    "publisher": {
      "@type": "Organization",
      "name": "Goodbye.cz",
      "logo": {
        "@type": "ImageObject",
        "url": "https://i.ibb.co/5nW0Cb6/logo-b.png"
      }
    },
    "mainEntityOfPage": {
      "@type": "WebPage",
      "@id": `https://goodbye.cz/slovnik-pojmu/${data.slug}/`,
    },
  }



  const options = {
    renderNode: {
      [BLOCKS.EMBEDDED_ASSET]: node => {
        const fluid = props.data.allContentfulAsset.nodes.find(n => n.file.url === node.data.target.fields.file["en-US"].url).fluid;
        return (
          <Img className="image" alt={data.h1} fluid={fluid} />
        );
      },

      [BLOCKS.EMBEDDED_ENTRY]: node => {
        return (
          <div className="videoWrapperOuter">
            <div className="videoWrapper" dangerouslySetInnerHTML={{__html: node.data.target.fields.code["en-US"]}} />
          </div>
        );
      },

      [BLOCKS.HEADING_2]: node => {
        return (
          <h2 id={node.content[0].value}>{node.content[0].value}</h2>
        );
      },

      [INLINES.HYPERLINK]: node => {
        return (
          <Link to={node.data.uri}>{node.content[0].value}</Link>
        );
      },

    },
  };


  return (
    <Layout V2>
      
      <SEO
        title={data.seoTitle}
        description={data.seoDescription}
        customTitle={true}
        image={`https:${data.image.fixed.src}`}
        article={true}
      />

      <script type="application/ld+json" dangerouslySetInnerHTML={{__html: JSON.stringify(structuredData)}} />

      <SlovnikTemplateInner>
        
        <div className="wrapper">
          <Breadscrum
            visible={true}
            stranky={[
              {name: "Domů", url: "/"},
              {name: "Slovník pohřebních pojmů", url: "/slovnik-pojmu/"},
              {name: data.h1, url: `/slovnik-pojmu/${data.slug}/`},
            ]}
          />
          <h1>{data.h1}</h1>
          <p className="perex">{data.perex2.perex2}</p>
          <Img className="hlavniFoto" alt={data.imageSeoAlt} fixed={data.image.fixed} />
          <div className="content">
            {documentToReactComponents(data.content.json, options)}
          </div>
        </div>

        
      </SlovnikTemplateInner>
      <SeVsimPomuzeme />
    </Layout>
  );
}

export default SlovnikTemplate;
